const actions = {
  ANALYTICS: {
    SEND: "analytics/send",
  },
};

const baseURL = process.env.REACT_APP_GATEWAY_API_URL;

const sendAnalytics = async ({ session_id, source, metrics }) => {
  let res = {
    success: true,
  };
  try {
    const response = await fetch(`${baseURL}/${actions.ANALYTICS.SEND}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ session_id, source, metrics }),
      keepalive: true,
    });
    const json = await response.json();
    if (!response.ok) {
      res.status = response.status;
      throw new Error(json.errorMessage);
    }
  } catch (e) {
    res.success = false;
    res.error = e;
  }
  return res;
};

export { sendAnalytics };
